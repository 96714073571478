import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styles from 'styles/components/loading.module.scss';

const Loading = () => (
  <div className={styles.loading}>
    <div>
      <Spin
        indicator={
          <div className={styles.loadingInner}>
            <LoadingOutlined style={{ fontSize: 44, color: 'black' }} spin />
          </div>
        }
      />
    </div>
    <div className={styles.text}>Loading</div>
  </div>
);

export default Loading;
