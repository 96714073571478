/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloseOutlined,
  ExclamationCircleFilled,
  FallOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RiseOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  Collapse,
  Radio,
  Select,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Header from 'components/header';
import Loading from 'components/loading';
import PerUserResponseDisplay from 'components/perUserResponseDisplay';
import QuestionAnswersModal from 'components/questionAnswersModal';
import SurveyNameStatus from 'components/surveyNameStatus';
import SurveyParticipation from 'components/surveyParticipation';
import TipEvolutionGraph from 'components/tipEvolutionGraph';
import UserListModal from 'components/userListModal';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { PureComponent, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  Tooltip as TooltipRechart,
  LineChart,
  Line,
  YAxis,
} from 'recharts';
import styles from 'styles/pages/survey-details.module.scss';
import { getDataPoints, getSurveyDetails } from 'utils/api';
import { DISPLAY_RESPONSES, KPI_TYPE, QUESTION_TYPES } from 'utils/constants';
import { isNumber, smoothScroll } from 'utils/utils';
const { default: PageWrapper } = require('components/pageWrapper');
const { Text } = Typography;

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-8} fill={stroke} fontSize={14} textAnchor="middle">
        {value}
      </text>
    );
  }
}

const CustomizedDot = (props) => {
  const { cx, cy, payload, surveyId } = props;

  if (payload.surveyId === surveyId) {
    return (
      <svg x={cx - 4} y={cy - 4} width={8} height={8} fill="white">
        <g transform="translate(4 4)">
          <circle r="4" fill="red" />
          <circle r="2" fill="white" />
        </g>
      </svg>
    );
  }

  return (
    <svg x={cx - 4} y={cy - 4} width={8} height={8} fill="white">
      <g transform="translate(4 4)">
        <circle r="4" fill="black" />
        <circle r="2" fill="black" />
      </g>
    </svg>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  const currentDataPoint = props.datapoints[payload.index];

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        dx={40}
        textAnchor="end"
        fill="#666"
        // transform="rotate(-35)"
      >
        {moment(currentDataPoint.publishedAt).format('L')}
      </text>
    </g>
  );
};

const EvolutionTag = ({ current, previous }) => (
  <Tooltip title={`Previous: ${previous}`}>
    <Tag
      color={previous > current ? 'red' : previous < current ? 'green' : 'blue'}
    >
      {previous > current ? (
        <FallOutlined />
      ) : previous < current ? (
        <RiseOutlined />
      ) : (
        <RiseOutlined />
      )}
      {` ${previous > current ? '-' : '+'}${Math.abs(
        current === previous
          ? 0
          : (((current - previous) / previous) * 100).toFixed(0)
      )}%`}
    </Tag>
  </Tooltip>
);

const SurveysDetails = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const [survey, setSurvey] = useState(null);
  const [users, setUsers] = useState(null);
  const [includeIncomplete, setIncludeIncomplete] = useState(false);
  const [newResponseInit, setNewResponseInit] = useState(null);
  const [searchParams] = useSearchParams();
  const bot = searchParams.get('bot');

  const [responseIdParam, setResponseIdParam] = useState(null);
  const [responseDisplayType, setResponseDisplayType] = useState(
    DISPLAY_RESPONSES.PER_QUESTION
  );

  const navigate = useNavigate();

  const [userListOpen, setUserListOpen] = useState(false);
  const openUserList = () => setUserListOpen(true);
  const closeUserList = () => setUserListOpen(false);

  const [answersData, setAnswersData] = useState(null);
  const [onlyWithCommentDefaultValue, setOnlyWithCommentDefaultValue] =
    useState(null);
  const openAnswers = (question) => setAnswersData(question);
  const openAnswersOnlyWithComments = (question) => {
    setOnlyWithCommentDefaultValue(true);
    openAnswers(question);
  };
  const closeAnswers = () => {
    setAnswersData(null);
    setOnlyWithCommentDefaultValue(null);
  };

  const getSurvey = () => {
    setLoading(true);
    getSurveyDetails(id, includeIncomplete)
      .then((data) => {
        setSurvey({
          ...data.survey,
          questionCount: data.survey.themes.reduce(
            (acc, el) => acc + el.questions.length,
            0
          ),
        });
        setUsers(data.idToUser);
        const allQuestions = data.survey.themes.reduce(
          (acc, element) => [...acc, ...element.questions],
          []
        );
        if (answersData) {
          //refresh content
          const theOneQuestion = allQuestions.find(
            (q) => q.id === answersData.id
          );
          setAnswersData(theOneQuestion);
        } else {
          const responseParam = query.get('responseId');
          const questionParam = query.get('questionId');
          const theOneQuestion = allQuestions.find(
            (q) => q.id === questionParam
          );
          if (responseParam && responseParam.length > 0 && theOneQuestion) {
            setResponseIdParam(responseParam);
            setAnswersData(theOneQuestion);
          }
        }
      })
      .catch((e) => {
        console.error('Error loading surveys', e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSurvey();
  }, [id, includeIncomplete]);
  useEffect(() => {
    const newResponseParam = query.get('newResponses');
    if (newResponseParam && newResponseParam.length > 0) {
      setResponseDisplayType(DISPLAY_RESPONSES.PER_USER);
      setNewResponseInit(newResponseParam);
    }
  }, []);
  // Need question id and kpi name
  const [selectedKpiForGraph, setSelectedKpiForGraph] = useState(null);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    if (selectedKpiForGraph) {
      getDataForSelectedKPI();
    }
  }, [selectedKpiForGraph]);

  const getDataForSelectedKPI = () => {
    if (selectedKpiForGraph) {
      setGraphLoading(true);
      getDataPoints(selectedKpiForGraph.questionId, selectedKpiForGraph.type)
        .then((data) => {
          setGraphData(data);
          scrollToGraph(selectedKpiForGraph.questionId);
        })
        .catch((e) => {
          console.error('Error loading data points', e);
        })
        .finally(() => {
          setGraphLoading(false);
        });
    }
  };

  const closeGraph = () => {
    setSelectedKpiForGraph(null);
  };

  const scrollToGraph = (questionId) => smoothScroll(`#question_${questionId}`);

  const handleChangeRecurring = (value) => {
    navigate(`/surveys/${value}`);
  };

  const onChangeIncludeIncomplete = (e) =>
    setIncludeIncomplete(e.target.checked);

  const onChangeResponseType = (e) => {
    setResponseDisplayType(e.target.value);
  };
  console.log(bot);

  return (
    <>
      {bot !== 'true' && <Header />}
      {bot === 'true' && (
        <>
          <br />
          <br />
        </>
      )}
      <PageWrapper width={880}>
        {bot !== 'true' && (
          <Breadcrumb
            className={styles.bread}
            items={[
              {
                title: (
                  <a href="/">
                    <HomeOutlined />
                  </a>
                ),
              },
              {
                title: <a href="/">Surveys</a>,
              },
              {
                title: survey?.name || '',
              },
            ]}
          />
        )}
        {loading && (
          <>
            <Loading />
          </>
        )}
        {survey && (
          <div className={styles.wrapper}>
            <div className={styles.statusWrapper}>
              <div className={styles.nameStatus}>
                <SurveyNameStatus survey={survey} showOptions={true} />
              </div>
              {survey.isRecurring && (
                <div className={styles.recurringSelect}>
                  <div className={styles.recurringLabel}>
                    This is a recurring survey, choose an occurence to display:
                  </div>
                  <Select
                    value={id}
                    style={{ width: 220 }}
                    onChange={handleChangeRecurring}
                    options={survey.recurringSurveys
                      .sort((a, b) => {
                        const adate = moment(a.publishedAt);
                        const bdate = moment(b.publishedAt);
                        if (adate.isBefore(bdate)) {
                          return 1;
                        }
                        if (bdate.isBefore(adate)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((r, i) => ({
                        value: r._id,
                        label: `${moment(r.publishedAt).format('Do MMM YYYY')}${
                          i === 0 ? ' (latest)' : ''
                        }`,
                      }))}
                  />
                </div>
              )}
            </div>
            {survey.incompleteResponsesCount &&
            survey.incompleteResponsesCount > 0 ? (
              <Alert
                className={styles.alertIncomplete}
                type="warning"
                showIcon
                title="Incomplete responses"
                message={
                  <div className={styles.titleAlert}>
                    <div className={styles.titleAlertLeft}>{`${
                      survey.incompleteResponsesCount
                    } incomplete responses (${(
                      (survey.incompleteResponsesCount / survey.usersCount) *
                      100
                    ).toFixed(0)}%)`}</div>
                    <div>
                      <Tag
                        color="volcano"
                        bordered={false}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div
                          style={{
                            fontSize: 32,
                            marginRight: 12,
                          }}
                        >
                          🌟
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                          Pulsy's super power!
                        </div>
                      </Tag>
                    </div>
                  </div>
                }
                description={
                  <div>
                    <div style={{ color: 'grey' }}>
                      Incomplete responses are users who answered at least one
                      question but <u>did not submit the survey.</u>
                    </div>
                    <div style={{ marginTop: 12 }}>
                      <Checkbox
                        checked={includeIncomplete}
                        onChange={onChangeIncludeIncomplete}
                        style={{ marginBottom: 12 }}
                        className={styles.checkboxIncomplete}
                      >
                        &nbsp;Include incomplete responses (
                        {survey.incompleteResponsesCount})
                      </Checkbox>
                    </div>
                  </div>
                }
              />
            ) : (
              <></>
            )}
            <div className={styles.participationWrapper}>
              <div className={styles.participationLeft}>
                <SurveyParticipation
                  survey={survey}
                  showIncomplete={includeIncomplete}
                />
              </div>
              <div className={styles.participationRight}>
                <div className={styles.userList}>
                  {survey.anonymous ? (
                    <div>{bot === 'true' ? ' ' : '🥷'} Anonymous survey</div>
                  ) : (
                    <div className={styles.avatarList}>
                      {(Object.values(users) || []).slice(0, 3).map((u) => {
                        return (
                          <div className={styles.avatarWrapper} key={u.name}>
                            <Avatar
                              key={u.name}
                              size={30}
                              src={u.profilePicture}
                              className={styles.avatar}
                            />
                            <div className={styles.avatarStatus}>
                              {u.incomplete ? (
                                <ExclamationCircleFilled
                                  style={{ color: 'orange' }}
                                  className={styles.statusIcon}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <a
                        onClick={openUserList}
                        className={styles.viewUsersList}
                      >{`View list (${survey.responsesCount}${
                        includeIncomplete
                          ? `+${survey.incompleteResponsesCount}`
                          : ''
                      })`}</a>
                      <UserListModal
                        isOpen={userListOpen}
                        close={closeUserList}
                        users={Object.values(users)}
                      />
                    </div>
                  )}
                </div>

                {survey.lastResponseSubmittedAt && (
                  <div className={styles.lastResponse}>
                    {`Last response ${moment(
                      survey.lastResponseSubmittedAt
                    ).fromNow()}`}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.responseWrapper}>
              <div className={styles.responseTitleWrapper}>
                <div className={styles.responseTitleLeft}>
                  <h2 className={styles.title}>Responses</h2>
                  <div className={styles.subtitle}>{`${
                    survey.themes.length
                  } ${pluralize('theme', survey.themes.length)}, ${
                    survey.questionCount
                  } ${pluralize('question', survey.themes.length)}`}</div>
                </div>
                <div>
                  <Radio.Group
                    onChange={onChangeResponseType}
                    value={responseDisplayType}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={DISPLAY_RESPONSES.PER_QUESTION}>
                      Per Question
                    </Radio.Button>
                    <Radio.Button value={DISPLAY_RESPONSES.PER_USER}>
                      Per User
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              {responseDisplayType === DISPLAY_RESPONSES.PER_QUESTION ? (
                <div>
                  <Collapse
                    className={styles.collapse}
                    defaultActiveKey={
                      bot === 'true'
                        ? survey?.themes?.reduce(
                            (acc, element) => [...acc, element.id],
                            []
                          )
                        : survey?.themes[0]?.id
                    }
                    items={survey.themes.map((theme, indexTheme) => ({
                      key: theme.id,
                      label: (
                        <span>
                          {theme.name}
                          <span className={styles.questionNumberInTitle}>{` - ${
                            theme.questions.length
                          } ${pluralize(
                            'question',
                            theme.questions.length
                          )}`}</span>
                        </span>
                      ),
                      children: (
                        <div>
                          {theme.questions.map((q, i) => {
                            const nbComments = q.results.answers.reduce(
                              (acc, el) =>
                                el.comment && el.comment.length > 0
                                  ? acc + 1
                                  : acc,
                              0
                            );
                            const nbIncomplete = q.results.answers.reduce(
                              (acc, el) => (el.incomplete ? acc + 1 : acc),
                              0
                            );
                            return (
                              <div
                                className={styles.questionWrapper}
                                key={q.id}
                              >
                                <div className={styles.questionHeader}>
                                  <div className={styles.questionHeaderLeft}>
                                    <Text mark style={{ marginRight: '8px' }}>
                                      <b>
                                        {` Q${i + 1} `}{' '}
                                        {q.type === QUESTION_TYPES.OPEN_ENDED &&
                                          ' - Open ended'}
                                      </b>
                                    </Text>
                                    {'  '}
                                    {q.text}
                                  </div>
                                  <div className={styles.questionHeaderRight}>
                                    {includeIncomplete && nbIncomplete > 0 ? (
                                      <Tooltip
                                        title={`${nbIncomplete} incomplete ${
                                          nbIncomplete > 1
                                            ? 'answers are'
                                            : 'answer is'
                                        } included`}
                                      >
                                        <Tag
                                          bordered={false}
                                          color={'grey'}
                                          style={{
                                            backgroundColor: '#ffa5002e',
                                            color: '#bb7900',
                                            fontSize: '14px',
                                            marginRight: 8,
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <ExclamationCircleFilled
                                            style={{ color: 'orange' }}
                                          />
                                          &nbsp;
                                          {`${nbIncomplete}`}
                                        </Tag>
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                    {q.type !== QUESTION_TYPES.OPEN_ENDED && (
                                      <Button
                                        type="link"
                                        disabled={nbComments <= 0}
                                        onClick={() => {
                                          openAnswersOnlyWithComments(q);
                                        }}
                                      >
                                        <span>
                                          {`${nbComments} ${pluralize(
                                            'comment',
                                            nbComments
                                          )}`}
                                        </span>
                                      </Button>
                                    )}
                                    <Button
                                      onClick={() => {
                                        openAnswers(q);
                                      }}
                                      type="primary"
                                      size="small"
                                    >{`${
                                      q.results.completeAnswersCount
                                    } ${pluralize(
                                      'answer',
                                      q.results.completeAnswersCount
                                    )}`}</Button>
                                  </div>
                                </div>
                                {q.type === QUESTION_TYPES.OPEN_ENDED && (
                                  <div className={styles.contentOpenEnded}>
                                    Click on Answers to read the comments.
                                  </div>
                                )}
                                {[QUESTION_TYPES.SELECT].includes(q.type) && (
                                  <div className={styles.vignettes}>
                                    <div
                                      className={styles.vignetteElement}
                                      style={{ flexBasis: '100%' }}
                                    >
                                      <div
                                        className={styles.vignetteGraph}
                                        style={{ minHeight: 300 }}
                                      >
                                        <ResponsiveContainer
                                          width="100%"
                                          height="100%"
                                        >
                                          <BarChart
                                            layout="vertical"
                                            width={350}
                                            height={100}
                                            data={Object.values(
                                              q.results.distribution
                                            ).map((d, i) => ({
                                              vote: d,
                                              name: Object.keys(
                                                q.results.distribution
                                              )[i],
                                            }))}
                                          >
                                            <XAxis type="number" />
                                            <YAxis
                                              dataKey="name"
                                              type="category"
                                            />
                                            <TooltipRechart />
                                            <Bar
                                              label={{
                                                position: 'right',
                                                formatter: (value) => {
                                                  return (
                                                    (
                                                      (value /
                                                        survey.responsesCount) *
                                                      100
                                                    ).toFixed(0) + '%'
                                                  );
                                                },
                                              }}
                                              dataKey="vote"
                                              fill="#8884d8"
                                            />
                                          </BarChart>
                                        </ResponsiveContainer>
                                      </div>
                                      <div>Distribution</div>
                                    </div>
                                  </div>
                                )}
                                {[
                                  QUESTION_TYPES.LIKERT_SCALE,
                                  QUESTION_TYPES.NPS_SCALE,
                                ].includes(q.type) && (
                                  <>
                                    {i === 0 &&
                                      indexTheme === 0 &&
                                      !q.results.previous?.nps &&
                                      !q.results.previous?.average &&
                                      !q.results.previous?.median && (
                                        <TipEvolutionGraph
                                          keyStorage={'tipGraphclosed'}
                                          content="If you use the same question several times, an evolution graph will
                                        be available."
                                        />
                                      )}
                                    {i === 0 &&
                                      indexTheme === 0 &&
                                      q.results.previous?.nps &&
                                      q.results.previous?.average &&
                                      q.results.previous?.median && (
                                        <TipEvolutionGraph
                                          keyStorage={'tip_see_graph'}
                                          content="Click on a KPI to see the evolution graph."
                                        />
                                      )}
                                    <div className={styles.vignettes}>
                                      <div
                                        className={`${styles.vignetteElement}${
                                          isNumber(q.results.previous?.nps)
                                            ? ` ${styles.vignetteElementClickable}`
                                            : ''
                                        }${
                                          selectedKpiForGraph?.questionId ===
                                            q.id &&
                                          selectedKpiForGraph?.type ===
                                            KPI_TYPE.NPS
                                            ? ` ${styles.selected}`
                                            : ''
                                        }`}
                                        onClick={() => {
                                          if (
                                            isNumber(q.results.previous?.nps)
                                          ) {
                                            setSelectedKpiForGraph({
                                              questionId: q.id,
                                              type: KPI_TYPE.NPS,
                                            });
                                          }
                                        }}
                                      >
                                        {graphLoading &&
                                          selectedKpiForGraph?.questionId ===
                                            q.id &&
                                          selectedKpiForGraph?.type ===
                                            KPI_TYPE.NPS && (
                                            <div
                                              className={
                                                styles.loaderInVignette
                                              }
                                            >
                                              <Spin
                                                indicator={
                                                  <div>
                                                    <LoadingOutlined
                                                      style={{ fontSize: 24 }}
                                                      spin
                                                    />
                                                  </div>
                                                }
                                              />
                                            </div>
                                          )}
                                        {isNumber(q.results.previous?.nps) ? (
                                          <div className={styles.previous}>
                                            <EvolutionTag
                                              current={q.results.nps}
                                              previous={q.results.previous.nps}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div className={styles.number}>
                                          {q.results.nps}
                                        </div>
                                        <div>
                                          NPS
                                          {q.type ===
                                            QUESTION_TYPES.LIKERT_SCALE && (
                                            <>
                                              {' '}
                                              <Tooltip
                                                title={
                                                  <div>
                                                    The question is a Likert
                                                    scale with answers from 1 to
                                                    5.
                                                    <div>
                                                      The NPS is usually
                                                      calculated from 0-10.
                                                      <br />
                                                      <br />
                                                      The likert scale has been
                                                      translated into NPS with
                                                      this equivalent:
                                                      <br />
                                                      <br />
                                                      <div>
                                                        {
                                                          ' 1-3 -> 0/6 Detractors'
                                                        }
                                                        <br />
                                                        {' 4 -> 7/8 Passives'}
                                                        <br />
                                                        {'5 -> 9/10 Promoters'}
                                                      </div>
                                                    </div>
                                                  </div>
                                                }
                                              >
                                                <InfoCircleOutlined />
                                              </Tooltip>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className={`${styles.vignetteElement}${
                                          q.results.previous?.average
                                            ? ` ${styles.vignetteElementClickable}`
                                            : ''
                                        }${
                                          selectedKpiForGraph?.questionId ===
                                            q.id &&
                                          selectedKpiForGraph?.type ===
                                            KPI_TYPE.AVERAGE
                                            ? ` ${styles.selected}`
                                            : ''
                                        }`}
                                        onClick={() => {
                                          if (
                                            isNumber(
                                              q.results.previous?.average
                                            )
                                          ) {
                                            setSelectedKpiForGraph({
                                              questionId: q.id,
                                              type: KPI_TYPE.AVERAGE,
                                            });
                                          }
                                        }}
                                      >
                                        {graphLoading &&
                                          selectedKpiForGraph?.questionId ===
                                            q.id &&
                                          selectedKpiForGraph?.type ===
                                            KPI_TYPE.AVERAGE && (
                                            <div
                                              className={
                                                styles.loaderInVignette
                                              }
                                            >
                                              <Spin
                                                indicator={
                                                  <div>
                                                    <LoadingOutlined
                                                      style={{ fontSize: 24 }}
                                                      spin
                                                    />
                                                  </div>
                                                }
                                              />
                                            </div>
                                          )}
                                        {isNumber(
                                          q.results.previous?.average
                                        ) ? (
                                          <div className={styles.previous}>
                                            <EvolutionTag
                                              current={q.results.average}
                                              previous={
                                                q.results.previous.average
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div className={styles.number}>
                                          <div>
                                            {q.results.average}
                                            <span className={styles.over}>
                                              {`/${
                                                q.type === 'LIKERT_SCALE'
                                                  ? 5
                                                  : 10
                                              }`}
                                            </span>
                                          </div>
                                        </div>
                                        <div>Average</div>
                                      </div>
                                      <div
                                        className={`${styles.vignetteElement}${
                                          q.results.previous?.median
                                            ? ` ${styles.vignetteElementClickable}`
                                            : ''
                                        }${
                                          selectedKpiForGraph?.questionId ===
                                            q.id &&
                                          selectedKpiForGraph?.type ===
                                            KPI_TYPE.MEDIAN
                                            ? ` ${styles.selected}`
                                            : ''
                                        }`}
                                        onClick={() => {
                                          if (
                                            isNumber(q.results.previous?.median)
                                          ) {
                                            setSelectedKpiForGraph({
                                              questionId: q.id,
                                              type: KPI_TYPE.MEDIAN,
                                            });
                                          }
                                        }}
                                      >
                                        {graphLoading &&
                                          selectedKpiForGraph?.questionId ===
                                            q.id &&
                                          selectedKpiForGraph?.type ===
                                            KPI_TYPE.MEDIAN && (
                                            <div
                                              className={
                                                styles.loaderInVignette
                                              }
                                            >
                                              <Spin
                                                indicator={
                                                  <div>
                                                    <LoadingOutlined
                                                      style={{ fontSize: 24 }}
                                                      spin
                                                    />
                                                  </div>
                                                }
                                              />
                                            </div>
                                          )}
                                        {isNumber(
                                          q.results.previous?.median
                                        ) ? (
                                          <div className={styles.previous}>
                                            <EvolutionTag
                                              current={q.results.median}
                                              previous={
                                                q.results.previous.median
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div className={styles.number}>
                                          <div>
                                            {q.results.median}
                                            <span className={styles.over}>
                                              {`/${
                                                q.type === 'LIKERT_SCALE'
                                                  ? 5
                                                  : 10
                                              }`}
                                            </span>
                                          </div>
                                        </div>
                                        <div>Median</div>
                                      </div>
                                      <div className={styles.vignetteElement}>
                                        <div className={styles.vignetteGraph}>
                                          <ResponsiveContainer
                                            width="100%"
                                            height="100%"
                                          >
                                            <BarChart
                                              width={150}
                                              height={100}
                                              data={Object.values(
                                                q.results.distribution
                                              ).map((d, i) => ({
                                                vote: d,
                                                name:
                                                  i +
                                                  (q.type ===
                                                  QUESTION_TYPES.LIKERT_SCALE
                                                    ? 1
                                                    : 0),
                                              }))}
                                            >
                                              <XAxis dataKey="name" />
                                              <TooltipRechart />
                                              <Bar
                                                dataKey="vote"
                                                fill="#8884d8"
                                              />
                                            </BarChart>
                                          </ResponsiveContainer>
                                        </div>
                                        <div>Distribution</div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {selectedKpiForGraph &&
                                  selectedKpiForGraph.questionId === q.id &&
                                  graphData && (
                                    <div
                                      className={styles.graphWrapper}
                                      id={`question_${q.id}`}
                                    >
                                      <div className={styles.graphHeader}>
                                        <div className={styles.graphTitle}>
                                          Evolution
                                        </div>
                                        <div>
                                          <Button
                                            key={'closegraph'}
                                            icon={<CloseOutlined />}
                                            type="primary"
                                            ghost
                                            onClick={closeGraph}
                                            size="small"
                                          >
                                            Close
                                          </Button>
                                        </div>
                                      </div>
                                      <div>
                                        <LineChart
                                          width={600}
                                          height={300}
                                          data={graphData.dataPoints}
                                          margin={{
                                            top: 50,
                                            right: 30,
                                            left: 20,
                                            bottom: 0,
                                          }}
                                        >
                                          <XAxis
                                            dataKey="name"
                                            // height={60}
                                            padding={{ left: 20, right: 20 }}
                                            tick={
                                              <CustomizedAxisTick
                                                datapoints={
                                                  graphData.dataPoints
                                                }
                                              />
                                            }
                                          />
                                          <YAxis />
                                          <TooltipRechart />
                                          <Line
                                            label={<CustomizedLabel />}
                                            dot={
                                              <CustomizedDot surveyId={id} />
                                            }
                                            type="monotone"
                                            dataKey="value"
                                            stroke="#8884d8"
                                            // activeDot={{ r: 8 }}
                                          />
                                        </LineChart>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </div>
                      ),
                    }))}
                  />
                </div>
              ) : (
                <div>
                  <PerUserResponseDisplay
                    survey={survey}
                    users={users}
                    refreshAnswers={getSurvey}
                    newResponseInit={newResponseInit}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </PageWrapper>
      {answersData && (
        <QuestionAnswersModal
          isOpen={answersData !== null}
          users={users}
          question={answersData}
          close={closeAnswers}
          onlyWithCommentDefaultValue={onlyWithCommentDefaultValue}
          isAnonymous={survey?.anonymous}
          defaultOpen={responseIdParam}
          refreshAnswers={getSurvey}
        />
      )}
    </>
  );
};

export default SurveysDetails;
