import {
  ClockCircleFilled,
  DownloadOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlayCircleFilled,
} from '@ant-design/icons';
import { Button, Dropdown, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from 'styles/components/surveyNameStatus.module.scss';
import { download } from 'utils/api';
import { DOWNLOAD_TYPE } from 'utils/constants';
import { getTextFromFrequency } from 'utils/utils';
import LoadingFullscreen from './loadingFullscreen';

const SurveyNameStatus = ({ survey, showOptions = false }) => {
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [searchParams] = useSearchParams();
  const bot = searchParams.get('bot');
  const { id } = useParams();
  const status =
    survey.deadline && moment(survey.deadline).isBefore(moment())
      ? 'CLOSED'
      : survey.status;

  const downloadODForCSV = (type = DOWNLOAD_TYPE.PDF) => {
    if (loadingDownload !== null) return;
    setLoadingDownload(type);
    download({
      surveyId: id,
      type,
    })
      .then((res) => {
        console.log(res);
        window.open(res.url, '_blank');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingDownload(null);
      });
  };

  const items = [
    {
      label: (
        <span onClick={() => downloadODForCSV(DOWNLOAD_TYPE.PDF)}>
          {loadingDownload === DOWNLOAD_TYPE.PDF ? (
            <LoadingOutlined />
          ) : (
            <DownloadOutlined />
          )}
          &nbsp;Download (.pdf)
        </span>
      ),
      key: '0',
    },
    {
      label: (
        <span onClick={() => downloadODForCSV(DOWNLOAD_TYPE.CSV)}>
          {loadingDownload === DOWNLOAD_TYPE.CSV ? (
            <LoadingOutlined />
          ) : (
            <DownloadOutlined />
          )}{' '}
          Download (.csv)
        </span>
      ),
      key: '1',
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.itemName}>{survey.name}</div>
          <div>
            <div className={styles.itemStatus}>
              <Tag
                color={status === 'LIVE' ? 'green' : 'volcano'}
                className={styles.itemStatusInner}
              >
                <div className={`${styles.circle} ${styles[status]}`}></div>
                {status}
              </Tag>
              {survey.anonymous && (
                <Tooltip title="Anonymous survey">
                  <Tag color="purple" className={styles.anonymous}>
                    {bot === 'true' ? ' ' : '🥷'}&nbsp;Anonymous
                  </Tag>
                </Tooltip>
              )}
              {survey.isRecurring && (
                <Tooltip title="Recurring Survey">
                  <Tag color="blue" className={styles.frequency}>
                    🔄&nbsp;{getTextFromFrequency(survey.frequency)}
                  </Tag>
                </Tooltip>
              )}
            </div>
            <div className={styles.itemDates}>
              {survey.publishedAt && (
                <div>
                  <Tooltip title="Published At" placement={['bottom']}>
                    <PlayCircleFilled />{' '}
                    {moment(survey.publishedAt).format('L')}
                  </Tooltip>
                </div>
              )}
              {survey.deadline && (
                <div className={styles.deadline}>
                  <Tooltip title="Deadline" placement={['bottom']}>
                    <ClockCircleFilled /> {moment(survey.deadline).format('L')}
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        {showOptions && bot !== 'true' && (
          <div className={styles.right}>
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button size="small" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </Button>
            </Dropdown>
          </div>
        )}
      </div>
      {loadingDownload && <LoadingFullscreen />}
    </>
  );
};

export default SurveyNameStatus;
