import Loading from './loading';
import styles from 'styles/components/loadingFullscreen.module.scss';

const LoadingFullscreen = () => (
  <div className={styles.loadingFullscreen}>
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loading />
    </div>
  </div>
);

export default LoadingFullscreen;
